import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import { ReactComponent as ReportIcon } from '@icons/wolfkit-light/alert-triangle-light.svg';
import IconButton from '@shared/ui/buttons/Button/IconButton';
const ReportUser = ({ className = undefined, }) => {
    const { t } = useTranslation();
    return (_jsx(Tooltip, { title: t('actions.report_user', { ns: 'common' }), placement: 'top', arrow: true, children: _jsx(IconButton, { variant: 'tinted', color: 'primary', size: 'small', IconComponent: ReportIcon }) }));
};
export default ReportUser;
